import AdministrationPdlListScreen from 'screens/control-panel/AdministrationPdlListScreen/AdministrationPdlListScreen'
import BackupScreen from 'screens/control-panel/BackupScreen/BackupScreen'
import DelayedProjectsScreen from 'screens/control-panel/DelayedProjectScreen/DelayedProjectScreen'
import FixedRateLoansScreen from 'screens/control-panel/FixedRateLoansScreen/FixedRateLoansScreen'
import InvestmentAdminListScreen from 'screens/control-panel/InvestmentAdminListScreen/InvestmentAdminListScreen'
import InvestmentByInvestorScreen from 'screens/control-panel/InvestmentByInvestorScreen/InvestmentByInvestorScreen'
import InvestorProfileAdminListScreen from 'screens/control-panel/InvestorProfileAdminListScreen/InvestorProfileAdminListScreen'
import InvestorProfileListScreen from 'screens/control-panel/InvestorProfileListScreen/InvestorProfileListScreen'
import InvestorProfileRegisterScreen from 'screens/control-panel/InvestorProfileRegisterScreen/InvestorProfileRegisterScreen'
import InvestorUserRegisterScreen from 'screens/control-panel/InvestorUserRegisterScreen/InvestorUserRegisterScreen'
import InvestorUsersListScreen from 'screens/control-panel/InvestorUsersListScreen/InvestorUsersListScreen'
import KpisScreen from 'screens/control-panel/kpisScreen/KpisScreen'
import LiquidatedInvestmentScreen from 'screens/control-panel/LiquidatedInvestmentScreen/LiquidatedInvestmentScreen'
import MaterialForPrescribersScreen from 'screens/control-panel/MaterialForPrescribersScreen/MaterialForPrescribersScreen'
import MyInvestmentsScreen from 'screens/control-panel/MyInvestmentsScreen/MyInvestmentsScreen'
import NotificationInvestorScreen from 'screens/control-panel/NotificationInvestorScreen/NotificationInvestorScreen'
import NotificationRegisterScreen from 'screens/control-panel/NotificationRegisterScreen/NotificationRegisterScreen'
import NotificationsDelayedListScreen from 'screens/control-panel/NotificationsDelayedListScreen/NotificationsDelayedListScreen'
import OwnersUserRegisterScreen from 'screens/control-panel/OwnersUserRegisterScreen/OwnersUserRegisterScreen'
import OwnersUsersListScreen from 'screens/control-panel/OwnerUserListScreen/OwnersUsersListScreen'
import PdlLiquidatedScreen from 'screens/control-panel/PdlLiquidatedScreen/PdlLiquidatedScreen'
import PdlLiquidateScreen from 'screens/control-panel/PdlLiquidateScreen/PdlLiquidateScreen'
import PdlListScreen from 'screens/control-panel/PdlListScreen/PdlListScreen'
import PreInvestorListScreen from 'screens/control-panel/PreInvestorsListScreen/PreInvestorListScreen'
import PreRegisterInvestorScreen from 'screens/control-panel/PreRegisterInvestorScreen/PreRegisterInvestorScreen'
import PrescriberListScreen from 'screens/control-panel/PrescriberListScreen/PrescriberListScreen'
import ProfileLastInvestmentScreen from 'screens/control-panel/ProfileLastInvestmentScreen/ProfileLastInvestmentScreen'
import ProfilePrescriberScreen from 'screens/control-panel/ProfilePrescriberScreen/ProfilePrescriberScreen'
import ProjectLiquidateScreen from 'screens/control-panel/ProjectLiquidateScreen/ProjectLiquidateScreen'
import ProjectListScreen from 'screens/control-panel/ProjectListScreen/ProjectListScreen'
import ProjectRegisterScreen from 'screens/control-panel/ProjectRegisterScreen/ProjectRegisterScreen'
import ProjectSettlementScreen from 'screens/control-panel/ProjectSettlementScreen/ProjectSettlementScreen'
import ProjectsScreen from 'screens/control-panel/ProjectsScreen/ProjectsScreen'
import RegisterUserScreen from 'screens/control-panel/RegisterUserScreen/RegisterUserScreen'
import ReportScreen from 'screens/control-panel/ReportScreen/ReportScreen'
import RetentionListScreen from 'screens/control-panel/RetentionListScreen/RetentionListScreen'
import SeeInfoScreen from 'screens/control-panel/SeeInfoScreen/SeeInfoScreen'
import StadisticScreen from 'screens/control-panel/StadisticScreen/StadisticScreen'
import StatisticsLoginScreen from 'screens/control-panel/StatisticsLoginScreen/StatisticsLoginScreen'
import StatisticsProjectInfoViewedScreen from 'screens/control-panel/StatisticsProjectInfoViewedScreen/StatisticsProjectInfoViewedScreen'
import UserProfileScreen from 'screens/control-panel/UserProfileScreen/UserProfileScreen'
import UsersListScreen from 'screens/control-panel/UsersListScreen/UsersListScreen'
import UserSecurityScreen from 'screens/control-panel/UserSecurityScreen/UserSecurityScreen'

const adminRoutes = [
	{
		path: '/user-page',
		name: 'Mi Información',
		component: <UserProfileScreen />,
		layout: '/admin',
	},
	{
		path: '/projects',
		name: 'Proyectos para Invertir',
		component: <ProjectsScreen />,
		layout: '/admin',
	},
	{
		path: '/my-investments',
		name: 'Mis Inversiones',
		component: <MyInvestmentsScreen />,
		layout: '/admin',
	},
	// Dropdown
	{
		path: '/material-prescribers',
		name: 'Material para Prescriptores',
		component: <MaterialForPrescribersScreen />,
		layout: '/admin',
	},
	{
		path: '/my-prescribers',
		name: 'Inversiones de mis Inversores',
		component: <ProfilePrescriberScreen />,
		layout: '/admin',
	},
	{
		path: '/pre-register-investor',
		name: 'Pre-registro de inversores',
		component: <PreRegisterInvestorScreen />,
		layout: '/admin',
	},
	{
		path: '/notifications',
		name: 'Notificaciones',
		component: <NotificationInvestorScreen />,
		layout: '/admin',
	},
	// Dropdown Administrar Mis Perfiles
	{
		path: '/investor-register',
		name: 'Registrar Perfil',
		component: <InvestorProfileRegisterScreen />,
		layout: '/admin',
	},
	{
		path: '/investor-profile-my-list',
		name: 'Perfiles',
		component: <InvestorProfileListScreen />,
		layout: '/admin',
	},
	// Admin routes
	// Dropdown Administrar Proyectos
	{
		path: '/list-projects',
		name: 'Proyectos',
		component: <ProjectListScreen />,
		layout: '/admin',
	},
	{
		path: '/register-project',
		name: 'Registrar Proyecto',
		component: <ProjectRegisterScreen />,
		layout: '/admin',
	},
	{
		path: '/liquidate-project',
		name: 'Liquidar Proyecto',
		component: <ProjectLiquidateScreen />,
		layout: '/admin',
	},
	// Dropdown Administrar PDL
	{
		path: '/pdls',
		name: 'Registrar PDL',
		component: <AdministrationPdlListScreen />,
		layout: '/admin',
	},
	{
		path: '/pdl-list',
		name: 'Listado de PDL',
		component: <PdlListScreen />,
		layout: '/admin',
	},
	{
		path: '/pdl-liquidate',
		name: 'Listado de PDL',
		component: <PdlLiquidateScreen />,
		layout: '/admin',
	},
	{
		path: '/pdl-liquidated',
		name: 'PDL liquidados',
		component: <PdlLiquidatedScreen />,
		layout: '/admin',
	},
	// Dropdown Administrar Inversiones
	{
		path: '/investment-list',
		name: 'Inversiones',
		component: <InvestmentAdminListScreen />,
		layout: '/admin',
	},
	// /admin/projects route defined in investor routes
	{
		path: '/liquidated-investment-list',
		name: 'Inversiones Liquidadas',
		component: <LiquidatedInvestmentScreen />,
		layout: '/admin',
	},
	{
		path: '/investments-by-investor',
		name: 'Inversión por inversor',
		component: <InvestmentByInvestorScreen />,
		layout: '/admin',
	},
	// Dropdown Estadísticas
	{
		path: '/stadistics',
		name: 'Inversiones por fecha e inversor',
		component: <StadisticScreen />,
		layout: '/admin',
	},
	{
		path: '/kpis',
		name: 'KPIs',
		component: <KpisScreen />,
		layout: '/admin',
	},
	{
		path: '/last-investments',
		name: 'Perfiles última inversión',
		component: <ProfileLastInvestmentScreen />,
		layout: '/admin',
	},
	{
		path: '/delayed-projects',
		name: 'Proyectos Retrasados',
		component: <DelayedProjectsScreen />,
		layout: '/admin',
	},
	{
		path: '/statistics/login',
		name: 'Inicio de Sesión',
		component: <StatisticsLoginScreen />,
		layout: '/admin',
	},
	{
		path: '/statistics/project-info-viewed',
		name: 'Ver Información',
		component: <StatisticsProjectInfoViewedScreen />,
		layout: '/admin',
	},
	{
		path: '/see-info',
		name: 'Ver Información',
		component: <SeeInfoScreen />,
		layout: '/admin',
	},
	// Dropdown Administradores
	{
		path: '/register-user',
		name: 'Registro de Administradores',
		component: <RegisterUserScreen />,
		layout: '/admin',
	},
	{
		path: '/user-list',
		name: 'Administradores',
		component: <UsersListScreen />,
		layout: '/admin',
	},
	// Dropdown Pre-Registro de Inversores
	{
		path: '/pre-register',
		name: 'Pre-Registro de Inversores',
		component: <PreRegisterInvestorScreen />,
		layout: '/admin',
	},
	{
		path: '/pre-investor-list',
		name: 'Pre-Inversores',
		component: <PreInvestorListScreen />,
		layout: '/admin',
	},
	// Dropdown Inversores
	{
		path: '/investor-register-user',
		name: 'Registrar nuevo Inversor',
		component: <InvestorUserRegisterScreen />,
		layout: '/admin',
	},
	{
		path: '/investor-list-users',
		name: 'Listado de Inversores',
		component: <InvestorUsersListScreen />,
		layout: '/admin',
	},
	{
		path: '/investor-list-profiles',
		name: 'Listado de Perfiles',
		component: <InvestorProfileAdminListScreen />,
		layout: '/admin',
	},
	{
		path: '/retentions',
		name: 'Retenciones',
		component: <RetentionListScreen />,
		layout: '/admin',
	},
	// Dropdown Propietarios
	{
		path: '/owners-register-user',
		name: 'Registro de Propietarios',
		component: <OwnersUserRegisterScreen />,
		layout: '/admin',
	},
	{
		path: '/owners-list-user',
		name: 'Listado de Propietarios',
		component: <OwnersUsersListScreen />,
		layout: '/admin',
	},
	// Dropdown Prescriptores
	{
		path: '/investor-prescriber-list',
		name: 'Listado de Prescriptores',
		component: <PrescriberListScreen />,
		layout: '/admin',
	},
	// Prestamos a T. Fijo
	{
		path: '/fixed-rate-loans',
		name: 'Prestamos a T. Fijo',
		component: <FixedRateLoansScreen />,
		layout: '/admin',
	},
	// Dropdown Gestionar Notificaciones
	{
		path: '/notification-delayed-list',
		name: 'Cambios de Fecha Fin',
		component: <NotificationsDelayedListScreen />,
		layout: '/admin',
	},
	{
		path: '/new-notification',
		name: 'Enviar notificación',
		component: <NotificationRegisterScreen />,
		layout: '/admin',
	},
	// Historial de Actividades
	{
		path: '/activities',
		name: 'Historial de Actividades de la Aplicación',
		component: <ReportScreen />,
		layout: '/admin',
	},
	// ????
	{
		path: '/backups',
		name: 'BackUps',
		component: <BackupScreen />,
		layout: '/admin',
	},
	{
		path: '/developers/project-settlement',
		name: 'liquidación de proyecto',
		component: <ProjectSettlementScreen />,
		layout: '/admin',
	},
	{
		path: '/security',
		name: 'Página para registrar MFA',
		component: <UserSecurityScreen />,
		layout: '/admin',
	},
]

export default adminRoutes
