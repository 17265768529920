import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core'
import { Close } from '@mui/icons-material'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import styles from '../styles/confirmPdlModalStyles'

const useStyles = makeStyles(styles)

const ValidationModal = ({ openValidationModal, setOpenValidationModal, missingValidation }) => {
	const classes = useStyles()

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={openValidationModal}
			keepMounted
			onClose={() => setOpenValidationModal(false)}
			aria-labelledby='user-modal-delete-title'
			aria-describedby='user-modal-delete-description'
		>
			<DialogTitle id='user-modal-delete-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={() => setOpenValidationModal(false)}
				>
					<Close className={classes.modalClose} />
				</Button>
				<Typography>Perfil Incompleto</Typography>
			</DialogTitle>

			<DialogContent id='user-modal-delete-description' className={classes.modalBody}>
				<GridContainer>
					<GridItem xs={12}>
						<Typography style={{ textAlign: 'center' }}>Información del perfil incompleta</Typography>
						<ul>
							{missingValidation?.length > 0 &&
								missingValidation.map((item, index) => (
									<li key={index}>
										<strong>{item}</strong>
									</li>
								))}
						</ul>
					</GridItem>
				</GridContainer>
			</DialogContent>
			<DialogActions className={classes.modalFooter}>
				<GridContainer>
					<GridItem xs={12}>
						<Button onClick={() => setOpenValidationModal(false)}>Salir</Button>
					</GridItem>
				</GridContainer>
			</DialogActions>
		</Dialog>
	)
}

export default ValidationModal
