export const salesWithProjectsColumns = () => {
	return [
		{
			Header: 'Dirección',
			width: 800,
			accessor: 'projectAddress',
		},
		{
			Header: 'Perfil Inversor',
			width: 800,
			accessor: 'profile',
		},
		{
			Header: 'Importe',
			width: 800,
			accessor: 'import',
		},
		{
			Header: 'Fecha y Hora de Registro',
			width: 800,
			accessor: 'date',
		},
		{
			Header: 'Usuario',
			width: 800,
			accessor: 'user',
		},
		{
			Header: 'Tipo de Interés',
			width: 800,
			accessor: 'typeOfInterest',
		},
		{
			Header: 'Acciones',
			accessor: 'actions',
		},
	]
}
