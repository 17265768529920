export const salesWithoutProjectsColumns = () => {
	return [
		{
			Header: 'Proyecto',
			accessor: 'projectAddress',
		},
		{
			Header: 'Fecha Máxima para Vender',
			accessor: 'maxSaleDate',
		},
		{
			Header: 'PDL Total Admisible',
			accessor: 'pdlAllowable',
		},
		{
			Header: 'PDL Total Realizado',
			accessor: 'totalPdl',
		},
		{
			Header: 'PDL Disponible',
			accessor: 'pdlAvailable',
		},
		{
			Header: 'Acciones',
			accessor: 'actions',
		},
	]
}
