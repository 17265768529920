import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { Assignment } from '@mui/icons-material'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import CustomWarningMessage from 'components/CustomWarningMessage/CustomWarningMessage'
import Loader from 'components/Loader/Loader'
import ReactTable from 'components/ReactTable/ReactTable'
import { salesWithoutProjectsColumns } from '../helpers/salesWithoutProjectsColumns'
import styles from '../styles/administrationPdlListScreenStyles'

const useStyles = makeStyles(styles)

const ProjectWithoutPdlCard = ({ salesWithoutProjects }) => {
	const classes = useStyles()

	const { loadingProjectFromSalesList } = useSelector((state) => state.projectsFromSales)
	return (
		<Card>
			<CardHeader color='primary' icon>
				<CardIcon color='primary'>
					<Assignment />
				</CardIcon>
				<h4 className={classes.cardIconTitle}>Proyectos sin PDL</h4>
			</CardHeader>
			<CardBody>
				{loadingProjectFromSalesList ? (
					<div className={classes.loader}>
						<Loader />
					</div>
				) : salesWithoutProjects && salesWithoutProjects.length > 0 ? (
					<div className={classes.tableContainer}>
						<ReactTable columns={salesWithoutProjectsColumns()} data={salesWithoutProjects} />
					</div>
				) : (
					<CustomWarningMessage text='No se encontraron proyectos' />
				)}
			</CardBody>
		</Card>
	)
}

export default ProjectWithoutPdlCard
