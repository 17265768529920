import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core'
import { Close } from '@mui/icons-material'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomCurrencyInput from 'components/CustomCurrencyInput/CustomCurrencyInput'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import { updateSigOfferDataByPdl } from 'local_redux/actions/projectAction'
import { SIG_PROJECT_UPDATE_BY_PDL_RESET } from 'local_redux/constants/projectConstant'
import styles from '../styles/confirmPdlModalStyles'

const useStyles = makeStyles(styles)
const numberFormat = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' })

const EditPdlAllowableModal = ({ openEditPdlAllowable, setOpenEditPdlAllowable, projectSales, setDateUpdateData }) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const [newPdlAllowable, setNewPdlAllowable] = useState(projectSales?.pdlAllowable || null)
	const [error, setError] = useState(false)

	const { successSigProjectUpdate, loadingSigProjectUpdate, errorSigProjectUpdate } = useSelector(
		(state) => state.sigProjectUpdatebyPdl,
	)

	useEffect(() => {
		if (successSigProjectUpdate) {
			setOpenEditPdlAllowable(false)
		}
	}, [successSigProjectUpdate])
	useEffect(() => {
		return () => {
			dispatch({ type: SIG_PROJECT_UPDATE_BY_PDL_RESET })
		}
	}, [])

	const handleUpdatePDL = () => {
		//data = {id, offerId, dateMaximumPurchase, newAmount}
		const data = {
			id: projectSales?._id,
			offerId: projectSales?.offerAccepted?._id,
			newAmount: Number(newPdlAllowable) + Number(projectSales?.offerAccepted?.arrasAmount) + Number(3000),
		}
		setDateUpdateData(data)
		dispatch(updateSigOfferDataByPdl(data))
	}
	const handlePdlAllowable = ({ floatValue: amount }) => {
		const minPdlAllowable = projectSales?.totalPdl || 0
		if (amount < minPdlAllowable) {
			setError(true)
		} else {
			setError(false)
		}
		setNewPdlAllowable(amount)
	}

	return (
		<>
			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modal,
				}}
				open={openEditPdlAllowable}
				keepMounted
				onClose={() => setOpenEditPdlAllowable(false)}
				aria-labelledby='user-modal-delete-title'
				aria-describedby='user-modal-delete-description'
			>
				<DialogTitle id='user-modal-delete-title' disableTypography className={classes.modalHeader}>
					<Button
						justIcon
						className={classes.modalCloseButton}
						key='close'
						aria-label='Close'
						color='transparent'
						onClick={() => setOpenEditPdlAllowable(false)}
					>
						<Close className={classes.modalClose} />
					</Button>
					<Typography>Nuevo PDL Total Admisible</Typography>
				</DialogTitle>

				<DialogContent id='user-modal-delete-description' className={classes.modalBody}>
					<GridContainer>
						<GridItem xs={12}>
							<Typography style={{ textAlign: 'center' }}>
								<strong>
									{projectSales.boe.location} {projectSales.boe.number}, {projectSales.boe.floor}{' '}
									{projectSales.boe.door}
								</strong>
							</Typography>
						</GridItem>
						<GridItem xs={12} sm={12}>
							<CustomCurrencyInput
								inputProps={{
									label: 'PDL Total Admisible',
									value: newPdlAllowable,
									onValueChange: handlePdlAllowable,
									name: 'pdlAllowable',
									id: 'pdlAllowable',
									error: error,
									helperText: `* El nuevo PDL total admisible no puede ser menor al PDL total realizado: ${numberFormat.format(
										projectSales.totalPdl,
									)}`,
									required: true,
								}}
							/>
						</GridItem>
						<GridItem xs={12}>
							{Boolean(errorSigProjectUpdate) && <SnackbarContent message={errorSigProjectUpdate} color='danger' />}
						</GridItem>
					</GridContainer>
				</DialogContent>
				<DialogActions className={classes.modalFooter}>
					<GridContainer>
						<GridItem xs={6}>
							<Button onClick={() => setOpenEditPdlAllowable(false)} block>
								Cancelar
							</Button>
						</GridItem>
						<GridItem xs={6}>
							<Button
								color={successSigProjectUpdate && 'success'}
								disabled={
									error ||
									!Boolean(newPdlAllowable) ||
									successSigProjectUpdate ||
									loadingSigProjectUpdate ||
									Boolean(errorSigProjectUpdate)
								}
								onClick={handleUpdatePDL}
								block
							>
								{loadingSigProjectUpdate
									? 'Actualizando...'
									: successSigProjectUpdate
									? 'Actualizado'
									: 'Actualizar PDL'}
							</Button>
						</GridItem>
					</GridContainer>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default EditPdlAllowableModal
