import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle'
import { grayColor, primaryBoxShadow, primaryColor, whiteColor } from 'assets/jss/material-dashboard-pro-react'

const styles = (theme) => ({
	...modalStyle(theme),
	autoComplete: {
		color: '#3C4858',
		marginTop: '13px',
		'& .MuiFormControl-root': {
			'& .MuiInputBase-root': {
				'&:before': {
					borderBottomWidth: '1px !important',
					borderBottomColor: grayColor[4] + '!important',
				},
				'&:after': {
					borderBottomColor: primaryColor[0] + '!important',
				},
				'& .MuiInput-input': {
					color: '#3C4858',
					fontSize: '14px',
					fontWeight: '400',
					lineHeight: '1.42857',
				},
				'& input': {
					'&,&::placeholder': {
						color: 'black',
						opacity: '1',
					},
					'&::placeholder': {
						color: grayColor[1],
					},
				},
			},
		},
	},
	selectMenuDiv: {
		marginTop: '12px',
		width: '100%',
		'& label': {
			color: '#AAAAAA !important',
			fontSize: '14px',
		},
		'& div': {
			'&:before': {
				borderColor: '#D2D2D2 !important',
				borderWidth: '1px !important',
			},
			'&:after': {
				borderColor: '#d1cdc2',
			},
		},
	},
	selectMenuItemSelectedMultiple: {
		backgroundColor: 'transparent !important',
		'&:hover': {
			backgroundColor: primaryColor[0] + '!important',
			color: whiteColor,
			...primaryBoxShadow,
			'&:after': {
				color: whiteColor,
			},
		},
		'&:after': {
			top: '16px',
			right: '12px',
			width: '12px',
			height: '5px',
			borderLeft: '2px solid currentColor',
			transform: 'rotate(-45deg)',
			opacity: '1',
			color: grayColor[2],
			position: 'absolute',
			content: '""',
			borderBottom: '2px solid currentColor',
			transition: 'opacity 90ms cubic-bezier(0,0,.2,.1)',
		},
	},
	scrollbar: {
		'&::-webkit-scrollbar': {
			width: '8px',
		},
		'&::-webkit-scrollbar-track': {
			background: '#999',
			borderRadius: '8px',
		},
		'&::-webkit-scrollbar-thumb': {
			background: grayColor[5],
			borderRadius: '8px',
		},
	},
	selectFormControl: {
		margin: '7px 0 17px 0 !important',
		'& > div': {
			'&:before': {
				borderBottomWidth: '1px !important',
				borderBottomColor: grayColor[4] + '!important',
			},
			'&:after': {
				borderBottomColor: primaryColor[0] + '!important',
			},
		},
		'& .MuiAutocomplete-popper': {
			'&::-webkit-scrollbar': {
				width: '8px',
			},
			'&::-webkit-scrollbar-track': {
				background: '#999',
				borderRadius: '8px',
			},
			'&::-webkit-scrollbar-thumb': {
				background: grayColor[5],
				borderRadius: '8px',
			},
		},
	},
})

export default styles
