import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	makeStyles,
	TextField,
	Typography,
} from '@material-ui/core'
import { Close } from '@mui/icons-material'
import { Autocomplete } from '@mui/material'
import CustomInput from 'components/CustomInput/CustomInput'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomCurrencyInput from 'components/CustomCurrencyInput/CustomCurrencyInput'
import ValidationModal from './ValidationModal'
import { registerProjectLiquidity } from 'local_redux/actions/projectLiquidityActions'
import { validationInfoTemplate } from '../helpers/validation-templates'
import styles from '../styles/confirmPdlModalStyles'

const useStyles = makeStyles(styles)
const numberFormat = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' })

const ConfirmPdlModal = ({ openConfirmPdl, setOpenConfirmPdl, projectSales, profilesList, loadingProfiles }) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const [projectLiquidityData, setProjectLiquidityData] = useState({
		profileInvestor: {},
		project: '',
		amountPdl: '',
		typeOfInterest: '12%',
	})
	const [maxAmount, setMaxAmount] = useState(false)
	const [openValidationModal, setOpenValidationModal] = useState(false)
	const [missingValidation, setMissingValidation] = useState([])

	const { successProjectLiquidityRegister, loadingProjectLiquidityRegister } = useSelector(
		(state) => state.projectLiquidityRegister,
	)
	const { userInfo } = useSelector((state) => state.userLogin)

	useEffect(() => {
		if (projectSales) {
			setMaxAmount(projectSales.pdlAvailable < projectLiquidityData.amountPdl ? true : false)
		}
	}, [projectSales, projectLiquidityData.amountPdl])
	useEffect(() => {
		if (successProjectLiquidityRegister) {
			setOpenConfirmPdl(false)
		}
	}, [successProjectLiquidityRegister])

	const handleRegisterPdl = () => {
		const validation = validationInfoTemplate({
			...projectLiquidityData.profileInvestor,
			addressOfTheRepresentative:
				projectLiquidityData?.profileInvestor?.addressOfTheRepresentative ||
				projectLiquidityData?.profileInvestor?.owner?.address,
		})

		if (!validation.success) {
			setMissingValidation(validation.missingValues)
			setOpenValidationModal(true)
			return
		}

		const offerAccepted = projectSales.projectOffers.find((offer) => offer.isAccepted === true)

		dispatch(
			registerProjectLiquidity({
				...projectLiquidityData,
				projectAddress: `${projectSales.boe.location} ${projectSales.boe.number}, ${projectSales.boe.floor} ${projectSales.boe.door}`,
				project: projectSales._id,
				user: userInfo?._id,
				maxSaleDate: offerAccepted?.dateMaximumPurchase,
			}),
		)
	}

	return (
		<>
			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modal,
				}}
				open={openConfirmPdl}
				keepMounted
				onClose={() => setOpenConfirmPdl(false)}
				aria-labelledby='user-modal-delete-title'
				aria-describedby='user-modal-delete-description'
			>
				<DialogTitle id='user-modal-delete-title' disableTypography className={classes.modalHeader}>
					<Button
						justIcon
						className={classes.modalCloseButton}
						key='close'
						aria-label='Close'
						color='transparent'
						onClick={() => setOpenConfirmPdl(false)}
					>
						<Close className={classes.modalClose} />
					</Button>
					<Typography>Confirmar PDL</Typography>
				</DialogTitle>

				<DialogContent id='user-modal-delete-description' className={classes.modalBody}>
					<GridContainer>
						<GridItem xs={12}>
							<Typography style={{ textAlign: 'center' }}>
								<strong>
									{projectSales.boe.location} {projectSales.boe.number}, {projectSales.boe.floor}{' '}
									{projectSales.boe.door}
								</strong>
							</Typography>
						</GridItem>
						<GridItem xs={12}>
							<FormControl fullWidth className={classes.selectFormControl}>
								<Autocomplete
									ListboxProps={{
										className: classes.scrollbar,
									}}
									className={classes.autoComplete}
									disablePortal
									id='zone'
									options={profilesList}
									onChange={(event, newValue) => {
										setProjectLiquidityData({ ...projectLiquidityData, profileInvestor: newValue })
									}}
									noOptionsText={<span>{loadingProfiles ? 'Cargando ...' : 'Sin resultados'}</span>}
									getOptionLabel={(e) => `${e.businessName}`}
									renderInput={(params) => (
										<TextField {...params} placeholder='Perfiles Inversores *' variant='standard' />
									)}
								/>
							</FormControl>
						</GridItem>
						<GridItem xs={12} sm={12}>
							<CustomCurrencyInput
								inputProps={{
									label: 'Importe PDL',
									value: projectLiquidityData.amountPdl,
									onValueChange: ({ floatValue }) => {
										setProjectLiquidityData({ ...projectLiquidityData, amountPdl: floatValue })
									},
									name: 'amountPdl',
									id: 'amountPdl',
									error: maxAmount,
									helperText:
										maxAmount &&
										`El monto agregado excede al monto PDL admisible. El maximo es ${numberFormat.format(
											projectSales.pdlAvailable,
										)}`,
									required: true,
								}}
							/>
						</GridItem>
						<GridItem xs={12} sm={12}>
							<CustomInput
								labelText='Tipo de Interés'
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									value: projectLiquidityData.typeOfInterest,
									onChange: (e) => {
										setProjectLiquidityData({ ...projectLiquidityData, typeOfInterest: e.target.value })
									},
									type: 'text',
								}}
							/>
						</GridItem>
					</GridContainer>
				</DialogContent>
				<DialogActions className={classes.modalFooter}>
					<GridContainer>
						<GridItem xs={6}>
							<Button onClick={() => setOpenConfirmPdl(false)} block>
								Cancelar
							</Button>
						</GridItem>
						<GridItem xs={6}>
							<Button
								color={successProjectLiquidityRegister && 'success'}
								disabled={maxAmount || (projectLiquidityData.amountPdl !== '' ? false : true)}
								onClick={handleRegisterPdl}
								block
							>
								{loadingProjectLiquidityRegister
									? 'Confirmando...'
									: successProjectLiquidityRegister
									? 'Confirmado'
									: 'Registrar PDL'}
							</Button>
						</GridItem>
					</GridContainer>
				</DialogActions>
			</Dialog>
			{openValidationModal && (
				<ValidationModal
					openValidationModal={openValidationModal}
					setOpenValidationModal={setOpenValidationModal}
					missingValidation={missingValidation}
				/>
			)}
		</>
	)
}

export default ConfirmPdlModal
