import { brandName } from 'shared/variables/general'
import PreRegisterInvestorScreen from 'screens/public-access/PreRegisterInvestorScreen/PreRegisterInvestorScreen'

const publicRoutes = [
  {
    path: '/pre-register',
    name: brandName,
    mini: 'P',
    component: <PreRegisterInvestorScreen />,
    layout: '/public',
  },
]

export default publicRoutes
